export const config = {
    globalPageTitle: process.env.VUE_APP_NAME,
    apiBaseUrl: process.env.VUE_APP_API_URL,
    apiGraphQlUrl: `${process.env.VUE_APP_API_URL}/graphql`,
    frontendUrl: process.env.VUE_APP_URL,
    stage: process.env.VUE_APP_STAGE,
    defaultLocale: 'de',
    cookies: {
        acknowledged: 'cookie-acknowledged',
        locale: 'locale'
    },
    matomo: {
        host: process.env.VUE_APP_MATOMO_URL,
        siteId: 1,
        trackerFileName: process.env.VUE_APP_MATOMO_TRACKER_FILENAME,
        enableLinkTracking: true,
        requireConsent: false,
        trackInitialView: true,
        disableCookies: false,
        enableHeartBeatTimer: false,
        heartBeatTimerInterval: 15,
        debug: false,
        userId: undefined,
        cookieDomain: undefined,
        domains: undefined,
        preInitActions: []
    }
}

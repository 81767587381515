import Cookies from 'js-cookie'
import { config } from '@/config'

export function dateFormat(date) {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }
    var d = new Date(date)
    return d.toLocaleDateString('de', options)
}

export function dateFormatLong(date) {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        weekday: 'long'
    }
    var d = new Date(date)
    return d.toLocaleDateString('de', options)
}

export function timeFormat(time) {
    const options = {
        hour: '2-digit',
        minute: '2-digit'
    }
    var d = new Date()
    var t = time.split(':')
    d.setHours(parseInt(t[0]), parseInt(t[1]), 0)
    return d.toLocaleTimeString('de', options)
}

export function scrollToFromMenu(item) {
    if(item.linkType == 'anchor'){
        scrollTo(item.anchor.replace('#', ''))
    }
}

export function scrollTo(name) {
    scrollToResolver(document.getElementById(name))
}

export function scrollToElement(el) {
    scrollToResolver(el)
}

function scrollToResolver(elem) {
    var jump = parseInt((elem.getBoundingClientRect().top - 50) * .2)
    document.body.scrollTop += jump
    document.documentElement.scrollTop += jump
    if (!elem.lastjump || elem.lastjump > Math.abs(jump)) {
        elem.lastjump = Math.abs(jump)
        setTimeout(function() { scrollToResolver(elem)}, 15)
    } else {
        elem.lastjump = null
    }
}

export function cookieAllowed() {
    var cookieAcknowledged = Cookies.get(config.cookies.acknowledged)
    return cookieAcknowledged && cookieAcknowledged == 'true'
}

export function url(item) {
    switch(item.linkType){
        case 'internal':
            return item.internalUrl
        case 'external':
            return item.externalUrl
        case 'anchor':
            return item.anchor
    }
}

export function isFrontPage() {
    return this.$route.path == '/'
}

export function isCmsPage() {
    return this.$route.path.includes('/page/')
}

export function isMobile() {
    return window.innerWidth <= 667
}

export function isMobileDevice() {
    return window.innerWidth <= 1100
}

export class VectorCartesian {
    constructor(x, y) {
        this.x = x
        this.y = y
    }

    set(x, y) {
        this.x = x
        this.y = y
    }

    polar() {
        var r = Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2))
        var phi = Math.atan2(this.y, this.x)
        return new VectorPolar(r, phi)
    }
}

export class VectorPolar {
    constructor(r, phi) {
        this.r = r
        this.phi = phi
    }

    set(r, phi) {
        this.r = r
        this.phi = phi
    }

    cartesian() {
        var x = this.r * Math.cos(this.phi)
        var y = this.r * Math.sin(this.phi)
        return new VectorCartesian(x, y)
    }
}

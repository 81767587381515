var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ drawer_open: _vm.drawerVisible }},[_c('div',{staticClass:"nav",class:{
        front_page: _vm.isFrontPage,
        submenu_open: _vm.$store.state.submenuOpen,
        post_page:
            this.$router.currentRoute.name == 'event'
            || this.$router.currentRoute.path == '/events'
            || this.$router.currentRoute.path == '/news'
            || this.$router.currentRoute.name == 'News Article'
            || this.$router.currentRoute.name == 'Podcast Episode'
            || this.$router.currentRoute.name == 'teammember'
            || this.$router.currentRoute.name == 'venture'
            || this.$router.currentRoute.name == 'ventures'
    },style:({ background: !_vm.drawerVisible ? '' : 'transparent' })},[_c('div',{staticClass:"nav-container left"},[_c('div',{staticClass:"menu-icon-container"},[(!_vm.$store.state.menuOpen && !_vm.$store.state.submenuOpen)?_c('div',{staticClass:"toggle",on:{"click":function($event){_vm.drawerVisible = true,
                    _vm.$store.commit('openMenu'), _vm.disableScroll()}}},[_c('div',{staticClass:"menu_icon"})]):_vm._e(),(_vm.$store.state.menuOpen)?_c('div',{staticClass:"toggle",class:{ mobile_hidden: _vm.$store.state.submenuOpen },on:{"click":function($event){_vm.drawerVisible = false, _vm.$store.commit('closeMenu'), _vm.$store.commit('closeSubmenu'), _vm.enableScroll()}}},[_c('div',{staticClass:"close_icon"})]):_vm._e(),(_vm.$store.state.submenuOpen)?_c('div',{staticClass:"toggle",on:{"click":function($event){_vm.closeMobileSubmenu(), _vm.$store.commit('closeSubmenu')}}},[_c('div',{staticClass:"back_icon"})]):_vm._e()])]),_c('div',{staticClass:"nav-container center"},[_c('div',{staticClass:"logo-container",class:{ 'submenu-open': _vm.$store.state.submenuOpen, 'default_page': !_vm.isFrontPage }},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"logo-img",class:{ 'menu-open': _vm.$store.state.menuOpen, 'submenu-open': _vm.$store.state.submenuOpen },attrs:{"width":"140px","height":"88px","alt":"REACH_Logo","src":require("@/assets/Logo_schwarz.svg")}})])],1)]),_c('div',{staticClass:"nav-container right"},[_c('InlineMenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.inlineMenu),expression:"inlineMenu"}],class:{ invert: _vm.drawerVisible },attrs:{"frontPageMenu":_vm.frontPageMenu,"socialIcons":_vm.socialIcons}})],1)]),_c('div',{staticClass:"menu-drawer",style:({
        width: _vm.drawerVisible ? '100vw' : '0',
        paddingLeft: _vm.drawerVisible ? '10px' : '0',
    })},[(_vm.drawerMenu)?_c('DrawerMenu',{attrs:{"drawerMenu":_vm.drawerMenu,"frontPageMenu":_vm.frontPageMenu,"socialIcons":_vm.socialIcons},on:{"closeDrawerMenu":function($event){return _vm.closeDrawerMenu()}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
/**
 * EventBus Plugin
 *
 * Adds an 'eventListeners' option to Vue components. See example below:
 *
 * ```
 * export default {
 *     eventlisteners: [
 *         {
 *             event: MyEvent,
 *             handler(event) {
 *                 // do something
 *             },
 *         }
 *     ],
 * }
 * ```
 *
 * All objects inside the eventListeners array are automatically hooked
 * into the components lifecycle.
 * Event classes are defined separately and imported per component, eg.:
 *
 * ```
 * export class MyEvent {
 *     constructor(arg) {
 *         this.arg = arg
 *     }
 * }
 * ```
 *
 * `this.arg` can be later used inside an event handler function like so:
 *
 * ```
 * handler(event) {
 *     var argFromConstructor = event.arg
 * }
 * ```
 */
export const EventBus = {
    /**
     * Plugin Setup
     *
     * @param Vue
     * @param options
     */
    install(Vue, options) {
        // Init event bus Vue instance
        this.$eventBus = new Vue()

        // Init event listeners method.
        // Will get called in the component lifecycle.
        Vue.prototype.initEventListeners = function(){
            var self = this
            if(this.$options.eventListeners){
                this.$options.eventListeners.forEach(function(l){
                    if(!l.once){
                        EventBus.listen(l.event, l.handler.bind(self))
                    } else {
                        EventBus.listenOnce(l.event, l.handler.bind(self))
                    }
                })
            }
        }

        // Destroy event listeners method.
        // Will get called in the component lifecycle.
        Vue.prototype.destroyEventListeners = function(){
            if(this.$options.eventListeners){
                this.$options.eventListeners.forEach(function(l){
                    EventBus.remove(l.event, l.handler)
                })
            }
        }

        // Hook event listeners into component lifecycle.
        // options.onMount determines if event listeners are initialized on
        // component creation or mounting
        if(options.onMount){
            Vue.mixin({
                mounted() {
                    this.initEventListeners()
                }
            })
        } else {
            Vue.mixin({
                created() {
                    this.initEventListeners()
                }
            })
        }
        Vue.mixin({
            beforeDestroy() {
                this.destroyEventListeners()
            }
        })
    },

    /**
     * Init an event listener
     *
     * @param eventClass
     * @param handler
     */
    listen(eventClass, handler) {
        this.$eventBus.$on(eventClass.name, handler)
    },

    /**
     * Init an event listener and listen only once
     *
     * @param eventClass
     * @param handler
     */
    listenOnce(eventClass, handler) {
        this.$eventBus.$once(eventClass.name, handler)
    },

    /**
     * Remove an event listener
     *
     * @param eventClass
     * @param handler
     */
    remove(eventClass, handler) {
        if(handler) {
            this.$eventBus.$off(eventClass.name, handler)
        } else {
            this.$eventBus.$off(eventClass.name)
        }
    },

    /** remove all event listeners from event bus */
    removeAll() {
        this.$eventBus.$off()
    },

    /**
     * Fire an event
     *
     * @param eventClass
     */
    publish(eventClass) {
        this.$eventBus.$emit(eventClass.constructor.name, eventClass)
    }
}

import gql from 'graphql-tag';

import { config } from '@/config';
import { getCurrentLocale } from '@/locales';

var locale = getCurrentLocale();

// Compatible with v4
const teamMember = `
data {
    attributes {
        name
        slug
        hasProfilePage
        image {
            data {
                attributes {
                    url
                    alternativeText
                    formats
                    ext
                }
            }
        }
        copyright
        position {
            ${locale}
        }
        hashtags {
            ${locale}
        }
        bio {
            ${locale}
        }
        telephone
        email
        website
        linkedIn
        instagram
        twitter
        facebook
    }
}`;

// Compatible with v4
const podcast = `
data {
    id
    attributes {
        slug
        title {
            ${locale}
        }
        description {
            ${locale}
        }
        image {
            ${locale} {
                data {
                    attributes {
                        url
                        alternativeText
                        formats
                        ext
                    }
                }
            }
            copyright
        }
        date
        copyright
        iFrameCode
        spotifyLink
        deezerLink
        podigeeUrl
        appleLink
        youtubeVideo
    }
}
`;

// Compatible with v4
const menuTiles = `
data {
    attributes {
        title {
            ${locale}
        }
        titleLinkType
        internalUrl
        anchor
        externalUrl {
            ${locale}
        }
        backgroundImage {
            data {
                attributes {
                    url
                    alternativeText
                    formats
                    ext
                }
            }
        }
        menuItems {
            title {
                ${locale}
            }
            linkType
            internalUrl
            externalUrl {
                ${locale}
            }
            anchor
        }
    }
}
`;

// TODO: Migrate to v4 Structure
const cmsBlocks = `
    ...on ComponentPageBuilderWysiwyg {
        __typename
        id
        wysiwygStyle: style
        text {
            ${locale}
        }
        anchorTag
        wysiwigWidth: width
    }

    ... on ComponentPageBuilderSplitColumns {
        __typename
        id
        Image {
            data {
                attributes {
                    url
                    alternativeText
                    formats
                    ext
                }
            }
        }
        TextContent {
            title_${locale}
            text_${locale}
        }
        backgroundColor
        contentLeft
        Button {
            linkType
            title_${locale}
            link_${locale}
        }
        whiteTextColor
        clipImage
    }

    ...on ComponentPageBuilderImageBlock {
        __typename
        id
        imageStyle: style
        backgroundStyle
        keepAspectRatio
        image {
            ${locale} {
                data {
                    attributes {
                        url
                        alternativeText
                        formats
                        ext
                    }
                }
            }
          copyright
        }
        anchorTag
    }

    ...on ComponentPageBuilderQuoteBlock {
        __typename
        id
        quoteStyle: style
        author {
            ${locale}
        }
        text {
            ${locale}
        }
        anchorTag
    }

    ...on ComponentPageBuilderHeaderBlock {
        __typename
        id
        headerStyle: style
        text {
            ${locale}
        }
        anchorTag
        htmlTag
    }

    ... on ComponentPageBuilderCounterBlock {
        __typename
        id
        counterStyle: style
        counters {
            id
            style
            number
            unit
            description_${locale}
        }
        anchorTag
    }

    ... on ComponentPageBuilderGraphicColumns {
        __typename
        id
        graphicColumnStyle: style
        header {
            ${locale}
        }
        graphicColumns: graphicColumn {
            id
            image {
                data {
                    attributes {
                        url
                        alternativeText
                        formats
                        ext
                    }
                }
            }
            title_${locale}
            description_${locale}
        }
        anchorTag
    }

    ...on ComponentPageBuilderNamedListBlock {
        __typename
        id
        namedListStyle: style
        listItemStyle: listStyle
        namedListItems: namedListItem {
            id
            name_${locale}
            text_${locale}
        }
        addButton
        buttonText {
            ${locale}
        }
        buttonLinkType
        buttonLink {
            ${locale}
        }
        anchorTag
    }

    ... on ComponentSliderSlider {
        __typename
        id
        sliderStyle: style
        ${locale} (pagination: {limit: 1000}) {
            id
            Title
            Caption
            Image {
                data {
                    attributes {
                        url
                        alternativeText
                        formats
                        ext
                    }
                }
            }
            Background_Color
            URL
            URL_Text
            Content
            Button_Text
            Button_URL
            Dark_Style
        }
        anchorTag
    }

    ... on ComponentPageBuilderSeparator {
        __typename
        id
        separatorStyle: style
        background
        anchorTag
    }

    ... on ComponentPageBuilderStartupSlider {
        __typename
        id
        startupSlides: startupSlide {
            id
            image {
                data {
                    attributes {
                        url
                        alternativeText
                        formats
                        ext
                    }
                }
            }
            copyright
            logo {
                data {
                    attributes {
                        url
                        alternativeText
                    }
                }
            }
            text_${locale}
        }
        anchorTag
    }

    ... on ComponentPageBuilderTextBlockWithButton {
        __typename
        id
        text {
            ${locale}
        }
        textBlockWithButtonStyle: style
        buttonText {
            ${locale}
        }
        buttonLink {
            ${locale}
        }
        linkType: buttonLinkType,
        buttons {
            linkType
            title_${locale}
            link_${locale}
        }
        anchorTag
        textBlockWithButtonWidth: width
    }

    ... on ComponentPageBuilderTextBlockWithIcons {
        __typename
        id
        text {
            ${locale}
        }
        textBlockWithIconsStyle: style
        iconLinks {
            id
            icon {
                data {
                    attributes {
                        url
                        alternativeText
                        formats
                        ext
                    }
                }
            }
            iconLink {
                ${locale}
            }
            iconLinkType
        }
        anchorTag
    }

    ... on ComponentPageBuilderTextColumns {
        __typename
        id
        textColumnsStyle: style
        textColumns {
            id
            title_${locale}
            text_${locale}
        }
        anchorTag
    }

    ... on ComponentPageBuilderPartnersBlock {
        __typename
        id
        partnersStyle: style
        title {
            ${locale}
        }
        description {
            ${locale}
        }
        partners {
            data {
                attributes {
                    text {
                        ${locale}
                    }
                    image {
                        ${locale} {
                            data {
                                attributes {
                                    url
                                    alternativeText
                                    formats
                                    ext
                                }
                            }
                        }
                        copyright
                    }
                    sortOrder
                    homepageLink
                }
            }
        }
        anchorTag
    }

    ... on ComponentPageBuilderTeamMembersBlock {
        __typename
        id
        teamMembers (pagination: {limit: 1000}) {
            style
            teamMember {
                ${teamMember}
            }
        }
        placeholders {
            data {
                attributes {
                    url
                    alternativeText
                }
            }
        }
        anchorTag
    }

    ... on ComponentPageBuilderSimplePodcastBlock {
        __typename
        id
        podcast {
            ${podcast}
        }
        anchorTag
    }

    ... on ComponentPageBuilderDetailedPodcastBlock {
        __typename
        id
        podcast {
            ${podcast}
        }
        anchorTag
    }

    ... on ComponentPageBuilderMultiplePodcastsBlock {
        __typename
        id
        useGroups
        showAll
        podcasts {
            ${podcast}
        }
        PodcastGroup {
            id
            groupCode
            groupTitle {
                ${locale}
            }
            podcasts (pagination: {limit: 1000}) {
                ${podcast}
            }
        }
        anchorTag
    }

    ... on ComponentPageBuilderTextTiles {
        __typename
        id
        tiles {
            title {
                ${locale}
            }
            text {
                ${locale}
            }
        }
        anchorTag
    }

    ... on ComponentPageBuilderGraphicTiles {
        __typename
        id
        tiles (pagination: {limit: 1000}){
            image {
                data {
                    attributes {
                        url
                        formats
                        alternativeText
                        ext
                    }
                }
            }
            text {
                ${locale}
            }
        }
        anchorTag
    }

    ... on ComponentPageBuilderTiledMenu {
        __typename
        id
        menuTiles {
            ${menuTiles}
        }
        anchorTag
    }

    ... on ComponentPageBuilderEcosystemAnimation {
        __typename
        id
        style
        subline {
            ${locale}
        }
        logosStep1 {
            image {
                data {
                    attributes {
                        url
                        alternativeText
                    }
                }
            }
            linkType
            internalUrl
            externalUrl
            anchor
        }
        logosStep2 {
            image {
                data {
                    attributes {
                        url
                        alternativeText
                    }
                }
            }
            linkType
            internalUrl
            externalUrl
            anchor
        }
        logosStep3 {
            image {
                data {
                    attributes {
                        url
                        alternativeText
                    }
                }
            }
            linkType
            internalUrl
            externalUrl
            anchor
        }
        anchorTag
    }

    ... on ComponentPageBuilderImageGallery {
        __typename
        id
        galleryStyle: style
        imageSlides: Image {
            id
            image {
                data {
                    attributes {
                        url
                        alternativeText
                        formats
                        ext
                    }
                }
            }
            caption{
                ${locale}
            }
            copyright
        }
    }

    ... on ComponentPageBuilderReachMemberForm {
        __typename
        id
        PreSelectedInterests
        PreSelectedTracks
        ShowMessageField
        InSpoiler
        SpoilerTitle {
            ${locale}
        }
    }

    ... on ComponentPageBuilderYouTube {
        __typename
        id
        oembedContent: link
        backgroundYoutubeStyle: style
        showTitle
    }

    ... on ComponentPageBuilderSpacerBlock {
        __typename
        id
        spacing
        customSpacingValueDesktop
        customSpacingValueTablet
        customSpacingValueMobile
        spacerBackgroundStyle: style
    }
`;

// Compatible to v4
const blogArticleBlocks = `
    ...on ComponentPageBuilderWysiwyg {
        __typename
        id
        text {
            ${locale}
        }
    }

    ...on ComponentPageBuilderImageBlock {
        __typename
        id
        imageStyle: style
        backgroundStyle
        keepAspectRatio
        image {
            ${locale} {
                data {
                    attributes {
                        url
                        alternativeText
                        formats
                        ext
                    }
                }
            }
          copyright
        }
        anchorTag
    }

    ...on ComponentPageBuilderQuoteBlock {
        __typename
        id
        quoteStyle: style
        author {
            ${locale}
        }
        text {
            ${locale}
        }
        anchorTag
    }

    ... on ComponentPageBuilderSimplePodcastBlock {
        __typename
        id
        podcast {
            ${podcast}
        }
        anchorTag
    }

    ... on ComponentPageBuilderImageGallery {
        __typename
        id
        galleryStyle: style
        imageSlides: Image {
            id
            image {
                data {
                    attributes {
                        url
                        alternativeText
                        formats
                        ext
                    }
                }
            }
            caption{
                ${locale}
            }
            copyright
        }
    }

    ... on ComponentPageBuilderReachMemberForm {
        __typename
        id
        PreSelectedInterests
        PreSelectedTracks
        ShowMessageField
        InSpoiler
        SpoilerTitle {
            ${locale}
        }
    }

    ... on ComponentPageBuilderYouTube {
        __typename
        id
        oembedContent: link
        backgroundYoutubeStyle: style
        showTitle
    }

    ... on ComponentPageBuilderX {
        __typename
        id
        oembedContent: link
    }

    ... on ComponentPageBuilderInstagram {
        __typename
        id
        postUrl
    }

    ... on ComponentPageBuilderLinkedIn {
        __typename
        id
        embedUrl
    }

    ... on ComponentPageBuilderSpacerBlock {
        __typename
        id
        spacing
        customSpacingValueDesktop
        customSpacingValueTablet
        customSpacingValueMobile
        spacerBackgroundStyle: style
    }
`;

// Compatible to v4
// replace id with slug!
export const teamMemberQuery = gql`query teamMember($slug: String!) {
    teamMembers(filters: {slug: {eq: $slug}}){
        data {
            attributes {
                name
                position{
                    ${locale}
                }
                hashtags {
                    ${locale}
                }
                bio {
                    ${locale}
                }
                image {
                    data {
                        attributes {
                            url
                            alternativeText
                            formats
                            ext
                        }
                    }
                }
                copyright
                telephone
                email
                website
                linkedIn
                instagram
                twitter
                facebook
            }
        }
    }
}`;

// Compatible to v4
// replace id with slug!
export const ventureQuery = gql`query venture($slug: String!) {
    ventureProfiles(filters: {slug: {eq: $slug}}) {
      data {
        attributes {
          Name
          logo {
            data {
              attributes {
                url
                alternativeText
                formats
                ext
              }
            }
          }
          teamImage {
            data {
              attributes {
                url
                alternativeText
                formats
                ext
              }
            }
          }
          website
          linkedin
          instagram
          podcast {
            data {
                id
                attributes {
                    slug
                }
            }
          }
          incubatorBatch
          shortDescription {
            ${locale}
          }
          description {
            ${locale}
          }
          marketFacts {
            ${locale}
          }
          businessFacts {
            ${locale}
          }
          targetGroup {
            ${locale}
          }
          lookingFor {
            ${locale}
          }
          mediaCoverage {
            ${locale}
          }
          publications {
            ${locale}
          }
          researchContext {
            ${locale}
          }
          teamMembers{
            name
            position {
                ${locale}
            }
            bio {
                ${locale}
            }
          }
        }
      }
    }
  }`;

// Compatible to v4
export const podcastQuery = gql`query podcast($slug: String!) {
    podcasts(filters: {slug: {eq: $slug}}) {
        data {
            id
            attributes {
                slug
                title {
                    ${locale}
                }
                description {
                    ${locale}
                }
                image {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                    copyright
                }
                date
                copyright
                iFrameCode
                spotifyLink
                deezerLink
                podigeeUrl
                appleLink
                youtubeVideo
            }
        }
    }
}`;

// Compatible to v4
export const menuQuery = gql` query menu($identifier: String!) {
    menus(filters: {identifier: {eq: $identifier}}, publicationState: ${config.stage}) {
        data {
            id
            attributes {
                title
                identifier
                menuItems: menuItem {
                    id
                    title {
                        ${locale}
                    }
                    icon {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    linkType
                    externalUrl {
                        ${locale}
                    }
                    internalUrl
                    anchor
                }
                menuTiles {
                    ${menuTiles}
                }
            }
        }
    }
}`;

// Compatible to v4
// Changed from aggregate { count } to meta -> pagination!
export const totalNewsArticlesQuery = gql`
  query totalNewsArticles {
    newsArticles {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

// Compatible to v4
export const newsArticlesQuery = gql` query newsArticles($limit: Int!, $start: Int!) {
    newsArticles(publicationState: ${config.stage}, sort: "dateOverride:desc", pagination: {start: $start, limit: $limit}) {
        data {
            attributes {
                title {
                    ${locale}
                }
                titleImage {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                }
                excerpt {
                    ${locale}
                }
                content {
                    ${locale}
                }
                urlKey
                createdAt
                dateOverride
            }
        }
    }
}`;

// Compatible to v4
export const newsArticleQuery = gql` query newsArticle($title: String!) {
    newsArticles(filters: {urlKey: {eq: $title}}, publicationState: ${config.stage}) {
        data {
            attributes {
                title {
                    ${locale}
                }
                titleImage {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                }
                headerImage {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                }
                metaImage {
                    ${locale} {
                      data {
                        attributes {
                          url
                          alternativeText
                          formats
                          ext
                        }
                      }
                    }
                  }
                metaDescription {
                    ${locale}
                }
                excerpt {
                    ${locale}
                }
                content {
                    ${locale}
                }
                urlKey
                createdAt
                dateOverride
                imageGallery {
                    __typename
                    id
                    galleryStyle: style
                    imageSlides: Image {
                        id
                        image {
                            data {
                                attributes {
                                    url
                                    alternativeText
                                    formats
                                    ext
                                }
                            }
                        }
                        caption{
                            ${locale}
                        }
                        copyright
                    }
                }
            }
        }
    }
}`;

// Compatible to v4
// Changed from aggregate { count } to meta -> pagination!
export const totalBlogArticlesQuery = gql`
  query totalBlogArticles {
    blogArticles {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

// Compatible to v4
export const blogArticlesQuery = gql` query blogArticles($limit: Int!, $start: Int!) {
    blogArticles(publicationState: ${config.stage}, sort: "dateOverride:desc", pagination: {start: $start, limit: $limit}) {
        data {
            attributes {
                slug
                title {
                    ${locale}
                }
                titleImage {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                }
                contentCategories {
                    data {
                        attributes {
                            name {
                                ${locale}
                            }
                            identifier
                            color
                            previewOverlayGraphic {
                                data {
                                    attributes {
                                        url
                                        alternativeText
                                        formats
                                        ext
                                    }
                                }
                            }
                        }
                    }
                }
                previewOverlayStrength
                createdAt
                dateOverride
            }
        }
    }
}`;

// Compatible to v4
export const blogArticleQuery = gql` query blogArticle($title: String!) {
    blogArticles(filters: {slug: {eq: $title}}, publicationState: ${config.stage}) {
        data {
            attributes {
                title {
                    ${locale}
                }
                titleImage {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                }
                headerImage {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                }
                metaImage {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                }
                metaDescription {
                    ${locale}
                }
                excerpt {
                    ${locale}
                }
                createdAt
                dateOverride
                contentCategories {
                    data {
                        attributes {
                            name {
                                ${locale}
                            }
                            identifier
                            color
                        }
                    }
                }
                articleBlocks {
                    ${blogArticleBlocks}
                }
                authors {
                    name
                    team_member {
                        data {
                            attributes {
                                name
                                slug
                                hasProfilePage
                            }
                        }
                    }
                }
            }
        }
    }
}`;

export const contentCategoriesQuery = gql` query contentCategories($articleEntity: JSON!) {
    contentCategories(filters: {articleEntity: {containsi: $articleEntity}}) {
        data {
            attributes {
                name {
                    ${locale}
                }
                identifier
                color
            }
        }
    }
}`;

// Compatible to v4
export const cmsPageQuery = gql` query cmsPage($title: String!) {
    cmsPages(filters: {urlKey: {eq: $title}}, publicationState: ${config.stage}) {
        data {
            attributes {
                title {
                    ${locale}
                }
                subtitle {
                    ${locale}
                }
                content {
                    ${locale}
                }
                urlKey
                metaTitle {
                    ${locale}
                }
                metaDescription {
                    ${locale}
                }
                metaImage {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
                type
                css
                cmsBlocks {
                    ${cmsBlocks}
                }
                Breadcrumbs{
                    Title_${locale}
                    URL
                }
            }
        }
    }
}`;

// Compatible to v4
export const globalOptionsQuery = gql` query globalOptions {
    globalOptions {
        data {
            attributes {
                optionTitle
                optionID
                optionType
                text {
                    ${locale}
                }
                url {
                    ${locale}
                }
                image {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                }
            }
        }
    }
}`;

// Compatible to v4
export const globalOptionQuery = gql` query globalOption($optionId: String!) {
    globalOptions(filters: {optionID: {eq: $optionId}}) {
        data {
            attributes {
                optionTitle
                optionID
                optionType
                text {
                    ${locale}
                }
                url {
                    ${locale}
                }
                image {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                }
            }
        }
    }
}`;

// Compatible to v4
export const sponsorsQuery = gql` query sponsors {
    sponsors(publicationState: ${config.stage}) {
        data {
            id
            attributes {
                text {
                    ${locale}
                }
                image {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                }
                sortOrder
            }
        }
    }
}`;

// Compatible to v4
export const applicationQuery = gql` query application {
    application(publicationState: ${config.stage}) {
        data {
            attributes {
                title {
                    ${locale}
                }
                subtitle {
                    ${locale}
                }
                applicationText {
                    ${locale}
                }
                applicationSuccess {
                    ${locale}
                }
                applicationError {
                    ${locale}
                }
                cmsBlocks {
                    ${cmsBlocks}
                }
            }
        }
    }
}`;

// Compatible to v4
export const enumQuery = gql`
  query enum($enumName: String!) {
    __type(name: $enumName) {
      name
      enumValues {
        name
      }
    }
  }
`;

// Compatible to v4
// Changed to meta -> pagination!
export const totalEventsQuery = gql`
  query totalEvents($format: String, $targetGroup: String) {
    events(
      filters: {
        eventFormat: { name: { eq: $format } }
        eventTargetGroup: { name: { eq: $targetGroup } }
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

// Compatible to v4
export const eventFormatsQuery = gql` query eventFormats {
    eventFormats {
        data {
            attributes {
                name
                translatedName {
                    ${locale}
                }
            }
        }
    }
}`;

// Compatible to v4
export const eventTargetGroupsQuery = gql` query eventTargetGroups {
    eventTargetGroups {
        data {
            attributes {
                name
                translatedName {
                    ${locale}
                }
            }
        }
    }
}`;

// Compatible to v4
export const eventsQuery = gql` query events($date: Date) {
    events(
        publicationState: ${config.stage},
        sort: "date:asc",
        filters: {
            date: {gte: $date}
        }
    ) {
        data {
            id
            attributes {
                title {
                    ${locale}
                }
                image {
                    copyright
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                }
                urlKey
                date
                startTime
                endTime
                place {
                    ${locale}
                }
                organizer {
                    ${locale}
                }
                contactPerson
                eventFormat {
                    data {
                        attributes {
                            name
                            translatedName {
                                ${locale}
                            }
                        }
                    }
                }
                eventTargetGroup {
                    data {
                        attributes {
                            name
                            translatedName {
                                ${locale}
                            }
                        }
                    }
                }
                content {
                    ${locale}
                }
                description {
                    ${locale}
                }
                organizerLogo {
                    data {
                        attributes {
                            url
                            alternativeText
                        }
                    }
                }
                metaTitle {
                    ${locale}
                }
                metaDescription {
                    ${locale}
                }
                metaImage {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    copyright
                }
            }
        }
    }
}`;

// Compatible to v4
export const venturesQuery = gql` query ventures {
    ventureProfiles(
        pagination: {limit: 1000},
        publicationState: ${config.stage},
        sort: "incubatorBatch:desc"
    ) {
        data {
            id
            attributes {
                Name
                slug
                logo {
                  data {
                    attributes {
                      url
                      alternativeText
                      formats
                      ext
                    }
                  }
                }
                incubatorBatch
                shortDescription {
                  ${locale}
                }
            }
        }
    }
}`;

//Compatible to v4
export const pastEventsQuery = gql` query events($date: Date) {
    events(
        publicationState: ${config.stage},
        sort: "date:desc",
        filters: {
            date: {lt: $date}
        }
    ) {
        data {
            id
            attributes {
                title {
                    ${locale}
                }
                image {
                    copyright
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                }
                urlKey
                date
                startTime
                endTime
                place {
                    ${locale}
                }
                organizer {
                    ${locale}
                }
                contactPerson
                eventFormat {
                    data {
                        attributes {
                            name
                            translatedName {
                                ${locale}
                            }
                        }
                    }
                }
                eventTargetGroup {
                    data {
                        attributes {
                            name
                            translatedName {
                                ${locale}
                            }
                        }
                    }
                }
                content {
                    ${locale}
                }
                description {
                    ${locale}
                }
                organizerLogo {
                    data {
                        attributes {
                            url
                            alternativeText
                        }
                    }
                }
                metaTitle {
                    ${locale}
                }
                metaDescription {
                    ${locale}
                }
                metaImage {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    copyright
                }
            }
        }
    }
}`;

// Compatible to v4
export const eventQuery = gql` query event($title: String!) {
    events(filters: {urlKey: {eq: $title}}, publicationState: ${config.stage}) {
        data {
            attributes{
                title {
                    ${locale}
                }
                image {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                    copyright
                }
                urlKey
                date
                startTime
                endTime
                place {
                    ${locale}
                }
                organizer {
                    ${locale}
                }
                contactPerson
                eventFormat {
                    data {
                        attributes {
                            name
                            translatedName {
                                ${locale}
                            }
                        }
                    }
                }
                eventTargetGroup {
                    data {
                        attributes {
                            name
                            translatedName {
                                ${locale}
                            }
                        }
                    }
                }
                content {
                    ${locale}
                }
                description {
                    ${locale}
                }
                organizerLogo {
                    data {
                        attributes {
                            url
                            alternativeText
                        }
                    }
                }
                metaTitle {
                    ${locale}
                }
                metaDescription {
                    ${locale}
                }
                metaImage {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    copyright
                }
                teamMember {
                    ${teamMember}
                }
                metaTitle {
                    ${locale}
                }
                metaDescription {
                    ${locale}
                }
                metaImage {
                    ${locale} {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
                registerUrl
                registerUrlText {
                    ${locale}
                }
                ExternalTeamMember {
                    name
                    description {
                        ${locale}
                    }
                    copyright
                    image {
                        data {
                            attributes {
                                url
                                alternativeText
                                formats
                                ext
                            }
                        }
                    }
                }
            }
        }
    }
}`;

// Compatible to v4
export const eventsCmsPageQuery = gql` query eventsCmsPage {
    eventList(publicationState: ${config.stage}) {
        data {
            attributes {
                title {
                    ${locale}
                }
                subtitle {
                    ${locale}
                }
                cmsBlocks {
                    ${cmsBlocks}
                }
            }
        }
    }
}`;

// Compatible to v4
export const venturesCmsPageQuery = gql` query venturesCmsPage {
    ventureList {
        data {
            attributes {
                title {
                    ${locale}
                }
                subtitle {
                    ${locale}
                }
                cmsBlocks {
                    ${cmsBlocks}
                }
            }
        }
    }
}`;

// Compatible to v4
export const generalSubContentQuery = gql` query generalSubContent {
    generalSubContent {
        data {
            attributes {
                cmsBlocks {
                    ${cmsBlocks}
                }
            }
        }
    }
}`;

// Compatible to v4
export const translationsQuery = gql` query translations {
    translations(
        pagination: { limit: 10000 }
    ) {
        data {
            attributes {
                code
                ${locale}
            }
        }
    }
}`;

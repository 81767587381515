<template>
    <div>
        <span v-for="(l, i) in locales" :key="l">
            <span class="lang-separator" v-if="i > 0">|</span>
            <a class="locale" :class="isLocaleSelected(l)" v-on:click="setLocale(l)" :href="$route.path">
                {{ l.toUpperCase() }}
            </a>
        </span>
    </div>
</template>

<script>
    import { locales, getLocale } from '@/locales'
    import { config } from '@/config'
    import { cookieAllowed } from '@/helper'

    import Cookies from 'js-cookie'

    export default {
        name: 'LangSwitcher',

        computed: {
            locales() {
                return Object.keys(locales)
            }
        },

        methods: {
            setLocale(locale) {
                console.log(locale)
                if(cookieAllowed){
                    Cookies.set(config.cookies.locale, locale)
                }
            },

            isLocaleSelected(locale) {
                var selected = Cookies.get(config.cookies.locale)
                if(!selected){
                    selected = getLocale()
                }
                return {selected: selected == locale}
            }
        }
    }
</script>

<style scoped lang="scss">
    .lang-switcher {
        .locale {
            padding: 0 4px;
            @include breakpoint('mobile') {
                padding: 0px;
            }
        }
        .lang-separator{
            padding:0 2px;
        }
        a {
            color: white;
            display: inline-block !important;

            &.selected {
                font-weight: 800;
            }
        }
    }
</style>

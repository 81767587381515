import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueApollo from 'vue-apollo'
import VueI18n from 'vue-i18n'
import VueMatomo from 'vue-matomo'
import VueMeta from 'vue-meta'
import showdown from 'showdown'
import VueSocialSharing from 'vue-social-sharing'
import removeMd from 'remove-markdown'

import { store } from '@/store'

import { config } from '@/config'
import { translationsQuery } from '@/queries'
import {
    initLocale,
    getCurrentLocale,
    processTranslations
} from '@/locales'

import { EventBus } from '@/event-bus'

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client/core'
import possibleTypes from './possibleTypes.json'

import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

const locale = initLocale()
store.commit('setLocale', locale.code)

Vue.use(VueApollo)
Vue.use(VueI18n)

Vue.use(EventBus, {onMount: true})

Vue.use(VueMatomo, {
    ...config.matomo,
    router: router
})

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.use(VueSocialSharing)

Vue.config.productionTip = false

const cache = new InMemoryCache({ possibleTypes })
const httpLink = createHttpLink({ uri: config.apiGraphQlUrl });

// await before instantiating ApolloClient, else queries might run before the cache is persisted
persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });
  
const apolloClient = new ApolloClient({
  cache,
  link: httpLink,
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    watchLoading(isLoading, countModifier) {
        store.commit('setLoadCount', store.state.loadCount + countModifier)
        if(store.state.loadCount == 1 && countModifier == 1){
            store.commit('setLoading', true)
        }
        if(store.state.loadCount == 0 && countModifier == -1){
            store.commit('setLoading', false)
        }
    }
})

const StrapiPlugin = {
    install(Vue) {
        Vue.prototype.localize = function(obj) {
            if(obj && getCurrentLocale() in obj) {
                return obj[getCurrentLocale()]
            }
        }

        Vue.prototype.localizeAlt = function(obj, field) {
            var localizedField = field + '_' + getCurrentLocale()
            if(localizedField in obj) {
                return obj[localizedField]
            }
        }
        
        Vue.prototype.getImageUrl = function(path) {
            return config.apiBaseUrl + path
        }
        Vue.prototype.getAssetUrl = function(path) {
            return config.frontendUrl + path
        } 
        // Scaled Image to load quicker. Implement size based on screen size and strapi breakpoints
        Vue.prototype.getScaledImageUrl = function(path, size="large") {
            if(path.formats != null){ // still unsure how JS handles null objects here
                var index = Object.keys(path.formats).indexOf(size);
                if(index == 1) {
                    return config.apiBaseUrl + path.formats[size]["url"]
                } else {
                    return config.apiBaseUrl + path.url
                }
            } else {
                return config.apiBaseUrl + path.url
            }
            
        }
        // Deprecated
        // Get Image format URLs
        Vue.prototype.getImageFormats = function(path) {
            if(path.formats != null){ // still unsure how JS handles null objects here
                var index = Object.keys(path.formats).indexOf(size);
                if(index == 1) {
                    return config.apiBaseUrl + path.formats[size]["url"]
                } else {
                    return config.apiBaseUrl + path.url
                }
            } else {
                return config.apiBaseUrl + path.url
            }
            
        }
        // Get Image srcsets from formats object
        Vue.prototype.getImageSrcsets = function(image) {
            var srcsets = {}
            // if no formats available, return null
            if (image.formats == null || Object.keys(image.formats).length === 0) {
                if (image.ext === ".svg") {
                    srcsets[image.ext] = this.getImageUrl(image.url)
                    return null
                }
                return null
            }
            for (const[_, format] of Object.entries(image.formats)) {
                if(!srcsets[format.ext]) {
                    srcsets[format.ext] = ""
                }
                srcsets[format.ext] += this.getImageUrl(format.url) + " " + format.width + "w, "
            }
            return srcsets
        }

        // Get Placeholder
        Vue.prototype.getPlaceholderSrcsets = function() { 
            const srcsets = {
                "jpeg": require('@/assets/placeholder.jpeg') + " 1024w",
                "webp": require('@/assets/placeholder.webp') + " 1024w",
            }
            return srcsets;
        }
        
        Vue.prototype.getCurrentUrl = function(route) {
            return config.frontendUrl + route.fullPath
        }

        var showdownExtension = function () {
            var ext = {
                type: 'html',
                regex: '<img[^>]+src="([^">]+)"',
                replace: '<img src="'+config.apiBaseUrl+'$1"'
            }
            return ext
        }
        const converter = new showdown.Converter({
            extensions: [showdownExtension]
        })
        Vue.prototype.md = function(text) {
            return converter.makeHtml(text)
        }
        Vue.prototype.removeMd = function(text) {
            return removeMd(text)
        }

        // Function to identify internal and external Links to render an <a>-Tag or <router-link>-Tag depending if link starts with "reach-euregio.de" or "/"
        Vue.prototype.isInternalLink = function(link) {
            // regex that accepts http and https as well as www and non-www
            // const regex = new RegExp("^(https?:\/\/)?((www\.)?(reach-euregio.de)?(\/?))", "i");
            const regex = new RegExp("^\/", "i");
            if(regex.test(link)) {
                return true
            }
            return false
        }
    }
}

Vue.prototype.$store = store

Vue.use(StrapiPlugin)

var initVue = function(i18n) {
    new Vue({
        router,
        render: h => h(App),
        apolloProvider,
        i18n
    }).$mount('#app')
}

// query translations and init vuejs after they were fetched
apolloClient.query({
    query: translationsQuery
}).then(

    // on fulfilled
    function(result){
        // use translation strings from query response
        processTranslations(result.data.translations)
        const i18n = new VueI18n({
            locale: locale.code,
            messages: store.state.translations
        })
        initVue(i18n)
    },

    // on rejected
    function(err){
        console.log(err)
        // use translation strings provided in the codebase as fallback
        const i18n = new VueI18n({
            locale: locale.code,
            messages: locale.translations
        })
        initVue(i18n)
    }
)

import Vue from 'vue'
import VueRouter from 'vue-router'
import VueBodyClass from 'vue-body-class'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue').then(m => m.default),
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/news',
        name: 'News',
        component: () => import('../views/News.vue').then(m => m.default)
    },
    {
        path: '/news/:title',
        name: 'News Article',
        component: () => import('../views/Article.vue').then(m => m.default),
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/blog',
        name: 'Blog',
        component: () => import('../views/Blog.vue').then(m => m.default),
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/blog/:title',
        name: 'Blog Article',
        component: () => import('../views/BlogArticle.vue').then(m => m.default),
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/page/:title',
        name: 'Page',
        component: () => import('../views/CmsPage.vue').then(m => m.default),
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/podcast',
        name: 'Podcast',
        component: () => import('../views/CmsPage.vue').then(m => m.default),
        props: {
            route: 'podcast'
        },
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/podcast/:slug',
        name: 'Podcast Episode',
        component: () => import('../views/Podcast.vue').then(m => m.default),
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/application',
        name: 'Application',
        component: () => import('../views/Application.vue').then(m => m.default),
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/events',
        name: 'events',
        component: () => import('../views/Events.vue').then(m => m.default),
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/events/:title',
        name: 'event',
        component: () => import('../views/Event.vue').then(m => m.default),
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/team',
        name: 'team',
        component: () => import('../views/CmsPage.vue').then(m => m.default),
        props: {
            route: 'team'
        },
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/team/:slug',
        name: 'teammember',
        component: () => import('../views/Team.vue').then(m => m.default),
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/start-ups',
        name: 'ventures',
        component: () => import('../views/Ventures.vue').then(m => m.default),
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/start-ups/:slug',
        name: 'venture',
        component: () => import('../views/Venture.vue').then(m => m.default),
        meta: {
            bodyClass: 'white'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('../views/404.vue').then(m => m.default)
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        // return desired position
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
      }
})

const vueBodyClass = new VueBodyClass(routes)
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) })

export default router

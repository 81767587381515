<template>
    <div>

        <div v-if="dataLoaded" class="fullmenu open">

            <div class="slide-navi">
                <div class="menu-wrapper">
                    <div class="main-menu-column">
                        <div class="parent-item-wrapper">
                            <ul>
                                <li v-for="menuTile in drawerMenu.attributes.menuTiles.data">
                                    <a v-on:click="selectedMenuTile = menuTile; openMobileSubmenu(), $store.commit('openSubmenu')" href="#">                               
                                        <span class="parent-menu-item" :class="{ active : selectedMenuTile == menuTile }">
                                            {{ localize(menuTile.attributes.title) }}
                                        </span>
                                        <img class="icon-next" src="/img/arrow_right.dfb7c0bf.svg" width="21" height="40" alt="Next Menu Stage"><!-- /img/arrow_right.bce6a8c7.svg -->
                                    </a>
                                </li>
                            </ul>
                            <InlineMenu
                                v-if="$store.state.menuOpen"
                                :frontPageMenu="frontPageMenu"
                                :socialIcons="socialIcons"
                                v-show="inlineMenu"
                                class="drawer_inline_menu"
                            />
                        </div>
                    </div>

                    <div class="sub-menu-column">
                        <div class="sub-menu-item-wrapper">
                            <ul v-for="menuTile in drawerMenu.attributes.menuTiles.data" v-show="selectedMenuTile == menuTile" class="submenu-items">
                                <li class="parent-title">{{ localize(menuTile.attributes.title) }}</li>
                                <li v-for="menuItem in menuTile.attributes.menuItems" >
                                    <router-link 
                                        v-if="menuItem.linkType == 'internal'"
                                        class="child-menu-item" 
                                        :to="url(menuItem)"
                                        v-on:click.native="clicked()"
                                    >{{ localize(menuItem.title) }}</router-link>
                                    <a v-else class="child-menu-item" :href="url(menuItem)" :target="target(menuItem)">
                                        {{ localize(menuItem.title) }}
                                    </a>
                                </li>
                            </ul>
                            <div class="mobile-image-wrapper">
                                <div 
                                    class="mobile-image-container" 
                                    v-bind:style="{ backgroundImage: 'url(' + getImageUrl(selectedMenuTile.attributes.backgroundImage.data.attributes.url) + ')' }">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="image-column">
                        <div class="image-wrapper">
                            <div 
                                class="image-container" >
                                <!-- TODO: Restore Animation -->
                                <picture>
                                    <template v-for="[format, set] of Object.entries(srcsets)">
                                        <source v-if="format !== originalFormat" :srcset="set" sizes="75vw" :type="'image/' + format.slice(1)">
                                    </template>
                                    <img loading="lazy" decoding="async" v-bind:srcset="srcsets[originalFormat]" :type="'image/' + originalFormat.slice(1)" sizes="75vw" :alt="selectedMenuTile.attributes.backgroundImage.data.attributes.alternativeText">
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InlineMenu from '@/components/InlineMenu'
    import Nav from '@/components/Nav'
    import { isFrontPage } from '@/helper'

    export default {
        name: 'DrawerMenu',

        components: {
            InlineMenu,
            Nav
        },

        data() {
            return {
                selectedMenuTile: this.drawerMenu.attributes.menuTiles.data[0],
                scrollBody: true,
                inlineMenu: true
            }
        },

        props: [
            "drawerMenu",
            "frontPageMenu",
            "socialIcons"
        ],

        computed: {
            dataLoaded() {
                return this.drawerMenu != null
            },

            isFrontPage: isFrontPage,

            srcsets(){
                let srcsets = []
                for (const tile of this.drawerMenu.attributes.menuTiles.data) {
                    srcsets.push(this.getImageSrcsets(tile.attributes.backgroundImage.data.attributes))
                }
                return this.getImageSrcsets(this.selectedMenuTile.attributes.backgroundImage.data.attributes)
            },
            
            hasWebp(){
                return this.srcsets['.webp'] ? true : false
            },

            originalFormat(){
                for (const[format, _] of Object.entries(this.srcsets)) {
                    if (format !== '.webp') {
                        return format
                    }
                }
                return null
            },
        },

        methods: {
            url(item) {
                switch(item.linkType){
                    case 'internal':
                        return item.internalUrl
                    case 'external':
                        return this.localize(item.externalUrl)
                    case 'anchor':
                        return item.anchor
                }
            },

            target(item) {
                switch(item.linkType){
                    case 'external':
                        return '_blank'
                    default:
                        return '_self'
                }
            },

            selectMenuTile(menuTile){
                this.selectedMenuTile = menuTile;
            },
            openMobileSubmenu(){
                document.querySelector('.slide-navi').setAttribute('class', 'slide-navi mobile-open')
                document.querySelector('.close_icon').setAttribute('class', 'close_icon hide_icon')
            },
            clicked(){
                this.$emit('closeDrawerMenu')
            },
            
        }
    }
</script>

<style scoped lang="scss">

@media only screen and (min-width: 667px) and (max-width:1100px) {
  .sub-menu-column{
        &:before{
            content: '';
            position: absolute;
            width: 100vw;
            background-color: white;
            margin-top: -130px;
            z-index: -1;
            height: 100px;
        }
    }
}
.drawer_inline_menu{
    position: relative;
    background-color: #1a2d34;
    text-align: left;
    padding: 0px 0px 50px 0px;
    margin-bottom: 55px;
    display: flex;
    justify-content: flex-start;
    @media only screen and (min-width: 1100px){
        display: none;
    }
    @media only screen and (min-width: 667px) and (max-width:1100px){
        text-align: center;
        justify-content: center;
        position: relative;
        width: 100%;
        margin: 0px;

    }
}
.mobile-open {
    .drawer_inline_menu{
        display: none;
    }
    .main-menu-column { //hide first slide overflow on mobile-xs size
        overflow: hidden;
    }
}

.mobile-image-container{
    display: none;
}
.back-button{
display: none;
}
.close{
    width: 0%!important;
    transition: all 0.2s ease-in-out;
}
.open{
    width: 100%;
    transition: all 0.2s ease-in-out;
}

 @media only screen and (max-width: 1100px) {
    .mobile-open {
        .menu-wrapper{
            transition: all 0.2s ease-in-out;
            display: inline-flex!important;
        }
        .main-menu-column{
            transition: all 0.2s ease-in-out;
            flex: 0!important;
            width: 0;
        }
        .sub-menu-column{
            transition: all 0.2s ease-in-out;
        }
        .inline-menu{
            display: none;
        }
        }
    .mobile-close{
        .menu-wrapper{
            transition: all 0.2s ease-in-out;
            display: block
        }
        .main-menu-column{
            transition: all 0.2s ease-in-out;
            flex:28.33%;
            width: unset;
        }
        .sub-menu-column{
            transition: all 0.2s ease-in-out;
            flex:33%;
        }
    }
}

.slide-navi{
    width: 100%;
    z-index: 9;
    height:100%;
    background-color: #273B41;
    position:absolute;
    .menu-wrapper{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        height: 100%;
        align-items: stretch;

         @media only screen and (max-width: 1100px) {
            width: 100%;
            display: block;
        }

        .main-menu-column::-webkit-scrollbar { width: 0 !important }
        .sub-menu-column::-webkit-scrollbar { width: 0 !important }
        
        .main-menu-column{
            flex: 28.33%;
            background-color: $timberGreen;
            height: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-end;
            scrollbar-width: none;
            -ms-overflow-style: none;
            @media only screen and (min-width: 1100px){
                overflow:auto;
            }
            @include breakpoint('mobile') {
                padding-top:30px;
            }

            .parent-item-wrapper{
                padding: 0px 26px 20px 26px;
                height: 85%;
                @media only screen and (min-width: 667px) and (max-width:1100px){
                    padding: 0px 150px 20px 150px;
                }
                 @media only screen and (max-width: 1100px) {
                    overflow: scroll !important;
                }
                ul{
                    margin-top: 0px;
                    @include breakpoint('mobile') {
                        margin-top:-20px;
                    }
                    @media only screen and (max-width: 375px) {
                        padding:14px;
                        margin-top: 10px;
                    }
                    li {

                    padding-left: 5px;
                        a {
                            cursor: pointer;
                            padding: 20px ;
                            display: flex;
                            justify-content: space-between;
                             @media only screen and (max-width: 1100px) {
                                padding:2px;
                                font-size: 1.5em;
                            }
                            @media only screen and (max-width: 375px) {
                                padding:0;
                                font-size: 30px;
                            }
                        }
                        @media only screen and (max-width: 667px) {
                            padding:35px 0px 35px 0px;
                        }
                        @media only screen and (min-width: 667px) and (max-width:1100px){
                            padding:35px 0px 35px 0px;
                        }
                    }

                    .parent-menu-item{
                        color: white;
                        font-family: 'ArdelaEdgeX02-Thin';
                        text-align: left;
                        &.active {
                            font-family: 'ArdelaEdgeX02-Bold';
                        }
                    }
                    .icon-next{
                        width: 20px;
                        vertical-align: middle;
                        position:relative;
                        left: 0;
                        margin-left: 20px;
                    }
                }
            }
        }
        .sub-menu-column{
            flex: 34.33%;
            background-color: white;
            display: flex;
            height: 100%;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-end;
            scrollbar-width: none;
            -ms-overflow-style: none;
            @media only screen and (min-width: 1100px){
                overflow:auto;
            }
            @media only screen and (min-width: 667px) and (max-width:1100px){
                justify-content: flex-start;
                margin-top: 100px;
                padding-top: 30px;
                height: 100%;
                overflow: scroll;
            }
            .sub-menu-item-wrapper{
                padding: 0 0 5px 0;
                height: 85%;
                width: 90%;
                align-self: center;
                @media only screen and (min-width: 667px) and (max-width:1100px){
                    width: 100%;
                    height: 85%;
                    .submenu-items{
                        padding:0px 150px;
                    }
                }
                @media only screen and (max-width: 667px) {
                    align-self: left;
                    padding: 0px 0px 5px 25px;
                    width:92%;
                    overflow:scroll;
                }
                ul {
                    text-align: left;
                    @media only screen and (max-width: 375px){
                        margin-bottom: 50px!important;
                    }
                    padding-top: 10px;
                    @media only screen and (min-width: 1100px)and (max-width: 1600px) {
                        padding-top:20px;
                    }
                    
                    li{
                        padding:22px 5px 22px 5px;
                        .child-menu-item{
                            color:#000;
                            font-size: 30px;
                            padding: 10px;
                            @include breakpoint('mobile') {
                                padding:10px 10px 10px 0px;
                            }
                        }
                    }
                    .parent-title{
                        padding: 15px;
                        font-size: 1.5em;
                        font-family: 'ArdelaEdgeX02-Bold';
                        @include breakpoint('mobile') {
                            padding: 15px 10px 15px 5px;
                        }
                    }
                }
                @media only screen and (min-width: 667px) and (max-width:1100px){
                    .mobile-image-wrapper{
                        display: flex;
                        padding: 40px;
                        height: 700px;
                        .mobile-image-container {
                            width: 100%;
                            display: block;  
                            transition: background-image 0.3s ease-in-out;
                            height: 570px;
                            background: none no-repeat; //url(https://reach-euregio.uni-muenster.de/uploads/menutile1_246e62bf1c.jpg) no-repeat;
                            background-size: cover;
                            background-position: center;
                            
                        }
                    }
                }
            }
        }

        .image-column{
            display: flex;
            flex-direction: column;
            height: 100%;
            flex-wrap: wrap;
            justify-content: flex-end;
            flex: 37.33%;
            background-color: white;
            margin-left: -1px;
             @media only screen and (max-width: 1100px) {
                display: none;
            }
            .image-wrapper{
                align-self: flex-end;
                width: 100%;
                height: 70%;
                padding-bottom: 80px;
                padding-top: 40px;
                @media only screen and (min-width: 1100px) and (max-width: 1600px) {
                    height: 80%;
                    padding-bottom: 10px;
                }
                .image-container {
                    display: flex;
                    transition: 0.3s ease-in-out; // background-image 0.3s ease-in-out;
                    height: 95%;
                    width: 90%;
                    background: none; //url(https://reach-euregio.uni-muenster.de/uploads/menutile1_246e62bf1c.jpg) no-repeat;
                    background-size: cover;
                    background-position: center;

                    picture {
                        width: 100%;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}

.fullmenu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background: $timberGreen;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @media only screen and (max-width: 1100px) {
        justify-content: unset;
        width: 100vw;
    }


    ul {
        list-style: none;
        padding: 20px 0;
        font-weight: bold;
        position: relative;
        li {
            padding: 20px;
            a {
                font-size: 32px;
                &:hover {
                    color: white;
                    opacity: 0.7;
                    transition: opacity 200ms;
                }
            }
        }
    }
}

@media only screen and (min-width: 1100px) {
    .parent-title{
    display: none!important;
    }
}
</style>

export default {
    de: {
        readMore: 'Weiterlesen',
        privacyPolicy: 'Datenschutzerklärung',
        cookieMessage: 'Diese Website verwendet Cookies. Wenn Sie die Website weiter nutzen, gehen wir davon aus, dass Sie hiermit einverstanden sind.',

        applicationGeneral: 'Allgemeine Informationen',
        applicationPrefix: 'Ansprache',
        applicationTitle: 'Titel',
        applicationFirstname: 'Vorname',
        applicationLastname: 'Nachname',
        applicationEmail: 'E-Mail',
        applicationOther: 'Sonstige',
        applicationSelect: 'Bitte auswählen',
        applicationApplicant: 'Wie heisst du?',
        applicationApplicantEmail: 'Unter welcher E-Mail-Adresse können wir dich kontaktieren?',
        applicationApplicantEmailRepeat: 'Zur Überprüfung erneut eingeben.',
        applicationTeamMembers: 'Wer ist ausser dir noch in der Gründung involviert?',
        applicationTeamMembersAddFirst: 'Person hinzufügen',
        applicationTeamMembersAdd: 'Weitere Person hinzufügen',
        applicationTeamMembersRemove: 'Person entfernen',
        applicationAllowContact: 'Sollen wir nur dich oder auch deine Mitgründer:innen kontaktieren?',
        applicationAllowContact_applicant: 'Nur mich',
        applicationAllowContact_all: 'Alle Teammitglieder',
        applicationFacility: 'Zu welcher dieser Einrichtungen hast du / habt ihr einen direkten Bezug durch Studium oder Forschung?',
        applicationFacility_wwu: 'WWU',
        applicationFacility_fh_muenster: 'FH Münster',
        applicationFacility_uni_twente: 'Universität Twente',
        applicationGroup: 'Zu welcher Gruppe zählst du dich / zählt ihr euch?',
        applicationGroup_employees: 'Mitarbeiter:innen',
        applicationGroup_alumni: 'Alumni',
        applicationGroup_scientific_employees: 'Wissenschaftliche:r Mitarbeiter:innen',
        applicationGroup_students: 'Studierende',
        applicationSubjectOfStudy: 'Welches Fach hast du / habt ihr studiert?',
        applicationConnection: 'Zu welchem Fachbereich / welcher Professorin / welchem Professor habt ihr einen speziellen Draht?',
        applicationConnectionInfo: 'Frage ist nur an Wissenschaftler:innen gerichtet',
        applicationAttention: 'Wie bist du / seid ihr auf uns aufmerksam geworden?',
        applicationAttention_lecture: 'Vorlesungswerbung',
        applicationAttention_social_media: 'Social Media',
        applicationAttention_podcast: 'Podcast',
        applicationAttention_event: 'Veranstaltung',
        applicationAttention_entrepreneurship: 'Lehrstuhl Entrepreneurship',
        applicationAttention_student_initiative: 'Studierendeninitiative',
        applicationAttention_other_founders: 'andere Gründungsteams',
        applicationIdea: 'Informationen zum Gründungsvorhaben',
        applicationIdeaName: 'Was ist der Name des Gründungsvorhabens / der Idee?',
        applicationIdeaDescription: 'Beschreibe Die Idee und die Innovation in drei bis vier Sätzen.',
        applicationIdeaProblem: 'Beschreibe das Problem, das die Idee lösen KÖNNTE, in zwei bis drei Sätzen.',
        applicationIdeaState: 'Was ist der aktuelle Stand des Gründungsvorhabens?',
        applicationSupport: 'Wo können wir dir/euch helfen, was suchst du / sucht ihr?',
        applicationSupport_coaching: 'Coaching',
        applicationSupport_pre_incubator: 'Pre-Inkubator',
        applicationSupport_incubator: 'Inkubator',
        applicationSupport_network: 'Netzwerk',
        applicationSupport_startup_grant: 'Gründungsstipendium',
        applicationSupport_financing: 'Finanzierung',
        applicationPrivacyPolicyConsent: 'Ja, ich habe die <a target="_blank" href="/page/impressum-und-datenschutz">Datenschutzerklärung</a> gelesen und stimme hiermit zu.',
        applicationRequiredFields: 'Diese Felder müssen ausgefüllt werden.',
        applicationSend: 'Bewerbung absenden',
        allPodcasts: 'alle Episoden',
        events: 'Events',
        visitEvent: 'Zum Event',
        date: 'Datum',
        time: 'Zeit',
        place: 'Ort',
        organizer: 'Veranstalter',
        format: 'Format',
        content: 'Inhalt',
        targetGroup: 'Zielgruppe',
        oclock: 'Uhr',
        eventsTitle: 'Mit dabei sein!',
        eventsSubtitle: 'Findet das passende Event für Euch!',
        resetFilters: 'Filter zurücksetzen',
        applyFilters: 'Filter anwenden',
        noEventsFound: 'Keine Events gefunden!',
        allEvents: 'Alle Veranstaltungen',
        signUpForFree: 'Kostenlos anmelden',
        share: 'Teilen'
    }
}

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        locale: 'de',
        loading: true,
        loadCount: 0,
        stashedCookies: [],
        translations: {},
        menuOpen: false,
        submenuOpen: false
    },

    mutations: {


        openMenu(state){
            state.menuOpen = true
        },

        closeMenu(state){
            state.menuOpen = false
        },

        openSubmenu(state){
            state.submenuOpen = true
        },

        closeSubmenu(state){
            state.submenuOpen = false
        },

        setLocale(state, locale) {
            state.locale = locale
        },

        setLoadCount(state, count) {
            state.loadCount = count
        },

        setLoading(state, loading) {
            state.loading = loading
        },

        addCookie(state, cookie) {
            state.stashedCookies.push(cookie)
        },

        resetCookies(state) {
            state.stashedCookies = []
        },

        initTranslations(state) {
            state.translations[state.locale] = {}
        },

        setTranslation(state, translation) {
            state.translations[state.locale][translation.code] = translation[state.locale]
        }
    }
})

<template>
    <div class="main menu" v-if="dataLoaded">
        
        <div class="menu-item lang-switcher">
            <LangSwitcher/>
        </div>

        <div class="menu-item" v-for="menuItem in socialIcons.attributes.menuItems" :key="menuItem.id">
            <a
                v-if="!menuItem.icon"
                class="burgerhide"
                :href="url(menuItem)"
                :target="target(menuItem)"
                aria-label="Find us on Social Media"
            >
                {{ localize(menuItem.title) }}
            </a>
            <a
                v-else
                class="burgerhide"
                :href="url(menuItem)"
                :target="target(menuItem)"
                aria-label="Find us on Social Media"
            >
                <img :src="getImageUrl(menuItem.icon.data.attributes.url)" width="26" height="26" alt="Social Icon">
            </a>
        </div>

    </div>
</template>

<script>
    import LangSwitcher from '@/components/LangSwitcher'

    import {
        url,
        scrollToFromMenu,
        isFrontPage,
        isCmsPage
    } from '@/helper'

    export default {
        name: 'InlineMenu',

        components: {
            LangSwitcher
        },

        props: [
            "frontPageMenu",
            "socialIcons"
        ],

        computed: {
            isFrontPage: isFrontPage,
            isCmsPage: isCmsPage,

            dataLoaded() {
                return this.frontPageMenu != null && this.socialIcons != null
            },
        },

        methods: {
            url(item) {
                switch(item.linkType){
                    case 'internal':
                        return item.internalUrl
                    case 'external':
                        return this.localize(item.externalUrl)
                    case 'anchor':
                        return item.anchor
                }
            },

            target(item) {
                switch(item.linkType){
                    case 'external':
                        return '_blank'
                    default:
                        return '_self'
                }
            },

            scrollTo: scrollToFromMenu
        }
    }
</script>

<style scoped lang="scss">
    .main,
    .menu {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 40px;
        flex-wrap: wrap;

        @media only screen and (max-width: 800px) {
            margin-right: 0;
        }

        .menu-item {
            padding: 0 13px;

            @media only screen and (max-width: 800px) {
                padding: 10px 13px;
            }

            @media only screen and (min-width: 1100px) and (max-width: 1200px) {
            padding: 0 10px;
            }
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    max-width: 26px;
                    height: auto;
                    @media only screen and (max-width: 375px){
                        max-width: 23px;
                    }
                }
            }
        }

        .lang-switcher {
            @media only screen and (max-width: 667px) {
                width: 100%;
            }
            @media only screen and (min-width: 667px) and (max-width:1100px){
                width: unset;
            }
        }
    }
</style>

<template>
    <div v-if="showBanner" class="cookie-notice">
        {{ $t('cookieMessage') }}
        <span class="vr-hr"></span>
        <router-link class="underlined" id="cookie-info" to="/page/impressum-und-datenschutz">{{ $t('privacyPolicy') }}</router-link>
        <img id="cookie-close" src="@/assets/x.svg" alt="Cookie Hinweis schließen" v-on:click="setCookie">
    </div>
</template>

<script>
    import { cookieAllowed } from '@/helper'
    import { config } from '@/config'
    import Cookies from 'js-cookie'

    export default {
        name: 'CookieNotice',

        data() {
            return {
                showBanner: false
            }
        },

        methods: {
            setCookie() {
                Cookies.set(config.cookies.acknowledged, 'true')
                this.$store.state.stashedCookies.forEach(function(data){
                    Cookies.set(data.cookie, data.value)
                })
                this.$store.commit('resetCookies')
                this.showBanner = false
            }
        },

        mounted() {
            if(!cookieAllowed()) {
                this.showBanner = true
            }
        }
    }
</script>

<style lang="scss">
    #cookie-info {
        cursor: pointer;
    }

    .cookie-notice {
        padding: 25px 100px 25px 50px;
        background: white;
        color: $timberGreen;
        font-size: 16px;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;

        a {
            color: $timberGreen;
            font-size: 16px;
        }

        .vr-hr {
            margin: 0 12px;
            height: 15px;
            display: inline-block;
        }

        .vr-hr,
        .underlined::after {
            background: $timberGreen;
        }

        &.fadeout {
            opacity: 0;
            visibility: hidden;
            transition: opacity 500ms ease-out,visibility 500ms ease-out;
        }
    }

    #cookie-close {
        cursor: pointer;
        width: 21px;
        position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
    }
</style>

import { config } from '@/config'
import { cookieAllowed } from '@/helper'
import { store } from '@/store'

import Cookies from 'js-cookie'

import de from '@/i18n/de'
import en from '@/i18n/en'

export const locales = {
    de: {
        codes: [
            'de','DE',
            'de-AT','de_AT','de-at','de_at',
            'de-DE','de_DE','de-de','de_de',
            'de-LI','de_LI','de-li','de_li',
            'de-LU','de_LU','de-lu','de_lu',
            'de-CH','de_CH','de-ch','de_ch'
        ],
        code: 'de',
        graphcms: 'de, en',
        translations: de
    },
    en: {
        codes: [
            'en','EN',
            'en-US','en_US','en-us','en_us',
            'en-GB','en_GB','en-gb','en_gb',
            'en-AU','en_AU','en-au','en_au',
            'en-BZ','en_BZ','en-bz','en_bz',
            'en-CA','en_CA','en-ca','en_ca',
            'en-GB','en_GB','en-gb','en_gb',
            'en-IE','en_IE','en-ie','en_ie',
            'en-IN','en_IN','en-in','en_in',
            'en-JM','en_JM','en-jm','en_jm',
            'en-MY','en_MY','en-my','en_my',
            'en-NZ','en_NZ','en-nz','en_nz',
            'en-PH','en_PH','en-ph','en_ph',
            'en-SG','en_SG','en-sg','en_sg',
            'en-TT','en_TT','en-tt','en_tt',
            'en-US','en_US','en-us','en_us',
            'en-ZA','en_ZA','en-za','en_za',
            'en-ZW','en_ZW','en-zw','en_zw'
        ],
        code: 'en',
        graphcms: 'en, de',
        translations: en
    }
}

export function getLocale() {
    const navigatorLocale = (navigator.languages && navigator.languages.length) ?
        navigator.languages[0] : navigator.userLanguage ||
        navigator.language ||
        navigator.browserLanguage ||
        config.defaultLocale

    Object.entries(locales).forEach(function([locale, data]){
        if(data.codes.includes(navigatorLocale)){
            return locale
        }
    })
    return config.defaultLocale
}

export function initLocale() {
    var locale = Cookies.get(config.cookies.locale)
    if(!locale) {
        locale = getLocale()
    }
    if(cookieAllowed()){
        Cookies.set(config.cookies.locale, locale)
    } else {
        store.commit('addCookie', {
            cookie: config.cookies.locale,
            value: locale
        })
    }
    return locales[locale]
}

export function getCurrentLocale() {
    var locale = Cookies.get(config.cookies.locale)
    if(locale){
        return locale
    } else {
        return getLocale()
    }
}

export function localize(obj) {
    return obj[getCurrentLocale()]
}

export function processTranslations(translations) {
    store.commit('initTranslations')
    translations.data.forEach(function(translation){
        store.commit('setTranslation', translation.attributes)
    })
}

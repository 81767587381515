<template>
    <div id="app">
        <CookieNotice v-once></CookieNotice>
        <Nav v-once></Nav>
        <div class="view-wrapper">
            <MiniLoader v-if="$store.state.loading"></MiniLoader>
            <router-view></router-view>
        </div>
        <Sponsors v-once></Sponsors>
        <Footer v-once></Footer>
    </div>
</template>

<script>
    import CookieNotice from '@/components/CookieNotice'
    import Nav from '@/components/Nav'
    import Footer from '@/components/Footer'
    import Sponsors from '@/components/Sponsors'
    import MiniLoader from '@/components/MiniLoader'
    import { globalOptionQuery } from '@/queries'
    import { config } from '@/config'
    import { getCurrentLocale } from '@/locales'
    
    export default {
        name: 'App',

        components: {
            MiniLoader,
            CookieNotice,
            Nav,
            Sponsors,
            Footer
        },

        data() {
            return {
                defaultMetaTitle: null,
                defaultMetaDescription: null,
                defaultMetaImage: null,
                miniLoaderTimeout: 1500,
                showMiniLoader: true
            }
        },

        apollo: {
            defaultMetaTitle: {
                query: globalOptionQuery,
                variables: {
                    optionId: 'default-meta-title'
                },
                fetchPolicy: 'network-only',
                update: data => data.globalOptions.data[0]
            },

            defaultMetaDescription: {
                query: globalOptionQuery,
                variables: {
                    optionId: 'default-meta-description'
                },
                fetchPolicy: 'network-only',
                update: data => data.globalOptions.data[0]
            },

            defaultMetaImage: {
                query: globalOptionQuery,
                variables: {
                    optionId: 'default-meta-image'
                },
                fetchPolicy: 'network-only',
                update: data => data.globalOptions.data[0]
            }
        },

        metaInfo() {
            var lang = getCurrentLocale()
            var meta = []

            if(this.metaTitle){
                meta.push({
                    vmid: 'og:title',
                    name: 'og:title',
                    content: this.metaTitle
                })
            }
            if(this.metaDescription){
                meta.push({
                    vmid: 'description',
                    name: 'description',
                    content: this.metaDescription
                })
                meta.push({
                    vmid: 'og:description',
                    name: 'og:description',
                    content: this.metaDescription
                })
            }
            if(this.metaImage){
                meta.push({
                    vmid: 'og:image',
                    name: 'og:image',
                    content: this.metaImage
                })
            }

            return {
                title: this.metaTitle,
                meta: meta,
                htmlAttrs: {
                    lang: lang
                }
            }
        },

        mounted() {
            var self = this
            this.$matomo && this.$matomo.trackPageView() && this.$matomo.enableLinkTracking()
            setTimeout(function(){
                self.showMiniLoader = false
            }, this.miniLoaderTimeout)
        },

        computed: {
            metaTitle() {
                if(this.defaultMetaTitle){
                    return config.globalPageTitle + ' - ' + this.localize(this.defaultMetaTitle.attributes.text)
                } else {
                    return null
                }
            },

            metaDescription() {
                if(this.defaultMetaDescription){
                    return this.localize(this.defaultMetaDescription.attributes.text)
                } else {
                    return null
                }
            },

            metaImage() {
                if(this.defaultMetaImage) {
                    return this.getImageUrl(this.localize(this.defaultMetaImage.attributes.image).data.attributes.url)
                } else {
                    return null
                }
            }
        }
    }
</script>

<style lang="scss">
    #app {
        max-width: unset;
    }

    .view-wrapper {
        min-height: 100vh;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .25s
    }

    .fade-enter, .fade-leave-to {
        opacity: 0
    }
</style>

<template>
    <footer v-if="dataLoaded">
        <div class="oneline">
            <a :href="localize(phone.attributes.url)">
                {{ localize(phone.attributes.text) }}
            </a>
            <span class="vr-hr"></span>
            <a :href="localize(mail.attributes.url)">
                {{ localize(mail.attributes.text) }}
            </a>
            <span class="vr-hr"></span>
            {{ localize(street.attributes.text) }}
            <span class="vr-hr"></span>
            {{ localize(city.attributes.text) }}
        </div>
        <span v-for="(menuItem, i) in footerMenu.attributes.menuItems" :key="menuItem.id">
            <span v-if="multipleMenuItems && i != 0" class="vr-hr"></span>
            <router-link
                class="underlined"
                :to="url(menuItem)"
                :target="target(menuItem)"
            >
                {{ localize(menuItem.title) }}
        </router-link>
        </span>
    </footer>
</template>

<script>
    import { menuQuery, globalOptionQuery } from '@/queries'

    export default {
        data() {
            return {
                footerMenu: null,
                phone: null,
                mail: null,
                street: null,
                city: null
            }
        },

        apollo: {
            footerMenu: {
                query: menuQuery,
                variables: {
                    identifier: 'footer-menu'
                },
                fetchPolicy: 'cache-first',
                update: data => data.menus.data[0]
            },

            phone: {
                query: globalOptionQuery,
                variables: {
                    optionId: 'footer-phone-number'
                },
                fetchPolicy: 'cache-first',
                update: data => data.globalOptions.data[0]
            },

            mail: {
                query: globalOptionQuery,
                variables: {
                    optionId: 'footer-email-address'
                },
                fetchPolicy: 'cache-first',
                update: data => data.globalOptions.data[0]
            },

            street: {
                query: globalOptionQuery,
                variables: {
                    optionId: 'footer-address-street'
                },
                fetchPolicy: 'cache-first',
                update: data => data.globalOptions.data[0]
            },

            city: {
                query: globalOptionQuery,
                variables: {
                    optionId: 'footer-address-city'
                },
                fetchPolicy: 'cache-first',
                update: data => data.globalOptions.data[0]
            }
        },

        computed: {
            dataLoaded() {
                return this.phone != null &&
                       this.mail != null &&
                       this.street != null &&
                       this.city != null &&
                       this.footerMenu != null
            },

            multipleMenuItems() {
                return this.footerMenu.attributes.menuItems.length > 1
            }
        },

        methods: {
            url(menuItem) {
                switch(menuItem.linkType){
                    case 'internal':
                        return menuItem.internalUrl
                    case 'external':
                        return this.localize(menuItem.externalUrl)
                    case 'anchor':
                        return menuItem.anchor
                }
            },

            target(menuItem) {
                switch(menuItem.linkType){
                    case 'external':
                        return '_blank'
                    default:
                        return '_self'
                }
            }
        }
    }
</script>

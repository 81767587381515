<template>
    <div :class="{ drawer_open: drawerVisible }">
        <div class="nav" :class="{
            front_page: isFrontPage,
            submenu_open: $store.state.submenuOpen,
            post_page:
                this.$router.currentRoute.name == 'event'
                || this.$router.currentRoute.path == '/events'
                || this.$router.currentRoute.path == '/news'
                || this.$router.currentRoute.name == 'News Article'
                || this.$router.currentRoute.name == 'Podcast Episode'
                || this.$router.currentRoute.name == 'teammember'
                || this.$router.currentRoute.name == 'venture'
                || this.$router.currentRoute.name == 'ventures'
        }"
            :style="{ background: !drawerVisible ? '' : 'transparent' }">
            <div class="nav-container left">
                <div class="menu-icon-container">
                    <!-- MENU ICON -->
                    <div v-if="!$store.state.menuOpen && !$store.state.submenuOpen" class="toggle" @click="drawerVisible = true,
                        $store.commit('openMenu'), disableScroll()">
                        <div class="menu_icon"></div>
                    </div>
                    <!-- CLOSE ICON -->
                    <div v-if="$store.state.menuOpen" :class="{ mobile_hidden: $store.state.submenuOpen }" class="toggle"
                        @click="drawerVisible = false, $store.commit('closeMenu'), $store.commit('closeSubmenu'), enableScroll()">
                        <div class="close_icon"></div>
                    </div>
                    <!-- BACK ICON -->
                    <div v-if="$store.state.submenuOpen" class="toggle"
                        v-on:click="closeMobileSubmenu(), $store.commit('closeSubmenu')">
                        <div class="back_icon"></div>
                    </div>
                </div>
            </div>

            <div class="nav-container center">
                <div class="logo-container" :class="{ 'submenu-open': $store.state.submenuOpen, 'default_page': !isFrontPage }">
                    <router-link to="/">
                        <img class="logo-img"
                            :class="{ 'menu-open': $store.state.menuOpen, 'submenu-open': $store.state.submenuOpen }"
                            width="140px" height="88px" alt="REACH_Logo" src="@/assets/Logo_schwarz.svg" />
                    </router-link>
                </div>
            </div>

            <div class="nav-container right">
                <InlineMenu :frontPageMenu="frontPageMenu" :socialIcons="socialIcons" v-show="inlineMenu"
                    :class="{ invert: drawerVisible }" />
            </div>

        </div>

        <div class="menu-drawer" :style="{
            width: drawerVisible ? '100vw' : '0',
            paddingLeft: drawerVisible ? '10px' : '0',
        }">
            <DrawerMenu v-if="drawerMenu" v-on:closeDrawerMenu="closeDrawerMenu()" :drawerMenu="drawerMenu"
                :frontPageMenu="frontPageMenu" :socialIcons="socialIcons" />
        </div>
    </div>
</template>

<script>
import DrawerMenu from '@/components/DrawerMenu'
import InlineMenu from '@/components/InlineMenu'
import { menuQuery } from '@/queries'
import {
    scrollToFromMenu,
    isFrontPage
} from '@/helper'


export default {
    name: 'Nav',

    components: {
        DrawerMenu,
        InlineMenu
    },

    data() {
        return {
            frontPageMenu: null,
            socialIcons: null,
            inlineMenu: true,
            drawerVisible: false,
            menuHidden: true,
        }
    },

    apollo: {
        frontPageMenu: {
            query: menuQuery,
            variables: {
                identifier: 'front-page-menu'
            },
            fetchPolicy: 'cache-and-network',
            update: data => data.menus.data[0]
        },
        socialIcons: {
            query: menuQuery,
            variables: {
                identifier: 'social-icons'
            },
            fetchPolicy: 'cache-and-network',
            update: data => data.menus.data[0]
        },
        drawerMenu: {
            query: menuQuery,
            variables: {
                identifier: 'burger-menu'
            },
            fetchPolicy: 'cache-and-network',
            update: data => data.menus.data[0]
        }
    },

    computed: {
        isFrontPage: isFrontPage
    },

    methods: {
        scrollTo: scrollToFromMenu,
        disableScroll() {
            document.body.classList.add('disable-scroll');
        },
        enableScroll() {
            document.body.classList.remove('disable-scroll');
        },
        closeMobileSubmenu() {
            document.querySelector('.slide-navi').setAttribute('class', 'slide-navi mobile-close')
            document.querySelector('.logo-img').setAttribute('class', 'logo logo-img menu-open')
        },
        closeDrawerMenu() {
            this.closeMobileSubmenu()
            this.drawerVisible = false
            this.$store.commit('closeMenu')
            this.$store.commit('closeSubmenu')
            this.enableScroll()
        }
    }
}
</script>

<style lang="scss" scoped>
.front_page .menu_icon {
    background: url("~@/assets/menu.png") no-repeat !important;
}

.mobile_hidden {
    @media only screen and (max-width: 1100px) {
        display: none;
    }
}

.menu-icon-container {
    z-index: 999; // 10
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;

    .menu_icon {
        background: url("~@/assets/menu.png") no-repeat;

        @media only screen and (max-width: 825px) {
            background: url("~@/assets/menu_dark.png") no-repeat;
        }

        background-size: contain;
        background-position: center !important;
        height: 27px;
        width: 27px;

    }

    .close_icon {
        position: fixed;
        background: url("~@/assets/menu_close.png") no-repeat;
        background-size: contain;
        background-position: center !important;
        height: 23px;
        width: 23px;
        margin-top: -10px;
    }

    .back_icon {
        width: 30px;
        height: 30px;
        background: url("~@/assets/arrow_left.svg") no-repeat;
        background-size: contain;
        background-position: left;
        filter: invert(0.8);

        @media only screen and (min-width: 1100px) {
            display: none;
        }
    }

    .toggle {
        width: 120px;
        border: 0;
        padding: 5px;
        cursor: pointer;
        transition: all 2s ease-in-out;
    }

}

/* DRAWER OPEN KLASSEN UND LOGOS */
.drawer_open .nav {
    position: fixed !important;
}

// Logo Color Manipulation
.logo-img {
    filter: invert(100%) sepia(0%) saturate(7432%) hue-rotate(73deg) brightness(112%) contrast(114%); //auch für frontpage

    &.menu-open {
        filter: invert(12%) sepia(6%) saturate(4497%) hue-rotate(151deg) brightness(92%) contrast(86%);

        @media only screen and (max-width: 1100px) {
            filter: invert(100%) sepia(0%) saturate(7432%) hue-rotate(73deg) brightness(112%) contrast(114%);
        }
    }

    &.submenu-open {
        @media only screen and (max-width: 1100px) {
            filter: invert(12%) sepia(6%) saturate(4497%) hue-rotate(151deg) brightness(92%) contrast(86%);
        }
    }
}

.default_page .logo-img {

    //für unterseiten
    @media only screen and (max-width: 825px) {
        filter: invert(12%) sepia(6%) saturate(4497%) hue-rotate(151deg) brightness(92%) contrast(86%);
    }

    @media only screen and (max-width: 1100px) {
        &.menu-open {
            filter: invert(100%) sepia(0%) saturate(7432%) hue-rotate(73deg) brightness(112%) contrast(114%);
        }

        &.submenu-open {
            filter: invert(12%) sepia(6%) saturate(4497%) hue-rotate(151deg) brightness(92%) contrast(86%);
        }
    }
}

.post_page {
    .logo-img {

        //für event seiten
        @media only screen and (max-width: 825px) {
            filter: invert(100%) sepia(0%) saturate(7432%) hue-rotate(73deg) brightness(112%) contrast(114%);
        }

        @media only screen and (max-width: 1100px) {
            &.menu-open {
                filter: invert(100%) sepia(0%) saturate(7432%) hue-rotate(73deg) brightness(112%) contrast(114%);
            }

            &.submenu-open {
                filter: invert(12%) sepia(6%) saturate(4497%) hue-rotate(151deg) brightness(92%) contrast(86%);
            }
        }
    }

    .menu_icon {
        background: url("~@/assets/menu.png") no-repeat;
        /*    @media only screen and (max-width: 825px){
            background:url("~@/assets/menu_dark.png") no-repeat;
        }*/
    }
}



.nav {
    display: flex;
    width: 100%;
    position: absolute;
    z-index: 999;
    height: 150px;
    justify-content: space-evenly !important;
    align-items: flex-start;
    color: white;

    .front_page {
        background: linear-gradient(180deg, rgba(39, 59, 65, 0.8) 0%, rgba(39, 59, 65, 0) 86%) !important;
    }

    @include breakpoint('desktop') {
        background: linear-gradient(180deg, rgba(39, 59, 65, 0.8) 0%, rgba(39, 59, 65, 0) 86%);
    }

    @media only screen and (min-width: 320px) {
        width: 100%;
        height: 105px;
        background: none;
        z-index: 999;

        &.front_page {
            background: linear-gradient(180deg, rgba(39, 59, 65, 0.8) 0%, rgba(39, 59, 65, 0) 86%);
        }
    }

    .nav-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &.left {
            justify-content: flex-start;
            margin-left: auto;
            z-index: 10;
            padding-top: 45px;

            @media only screen and (max-width: 1100px) {
                padding-top: 35px;
            }

            .menu-icon-container {
                margin-left: 50px;
                width: 50px;

                @include breakpoint('desktop-xs') {
                    margin-left: 50px;
                    flex: 0.5;
                }

                @media only screen and (max-width: 1100px) {
                    margin-left: 30px;
                }
            }
        }

        &.center {
            z-index: 10;

            .logo-container {
                text-align: center;
                padding-top: 25px;
                height: 100%;
                width: 140px;

                @media only screen and (max-width: 820px) {
                    width: 115px;
                    padding-top: 15px;
                }

                @media only screen and (max-width: 667px) {
                    padding-top: 20px;

                    img {
                        height: 63px;
                    }
                }
            }
        }

        &.right {
            justify-content: flex-end;
            margin-right: auto;
            z-index: 10;
            padding-top: 45px;

            .invert {
                filter: invert(0.8);
            }

            @media only screen and (max-width: 1100px) {
                visibility: hidden;
            }
        }
    }
}

.nav .front_page {
    background: linear-gradient(180deg, rgba(39, 59, 65, 0.8) 0%, rgba(39, 59, 65, 0) 86%) !important;
}

.menu-drawer {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    overflow: hidden;
    height: calc(100vh - 140px);
    padding-left: 0;
    padding-top: 140px;
    z-index: 998;
    transition: all 0.2s;

    @include breakpoint('tablet') {
        padding-top: 0px;
        height: 100vh;
    }
}
</style>